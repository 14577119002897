import React from 'react'
import Backend from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";
import i18n from "i18next";

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: function (value, format, lng) {
                if (format === 'uppercase') return value.toUpperCase();
                if (format === 'lowercase') return value.toLowerCase();
                if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
                return value;
            },
        },

        //CAMBIARE QUI loadPath PER PRODUCTION A SECONDA DELLE ESIGENZE (es. TOMCAT) //IN SVILUPPO QUESTA PARTE NON SERVE, TUTTA A COMMENTO
        // backend: {
        //     loadPath: '/palantirbim_fe/locales/{{lng}}/{{ns}}.json'
        // }
    });

export default i18n;