import {createSlice} from '@reduxjs/toolkit'

export const initialState = []

export const allSelectTypeIfcSlice = createSlice({
    name: 'allSelectTypeIfc',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?[...action.payload]:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = allSelectTypeIfcSlice.actions

export const selectAllSelectTypeIfc = (state) => state.allSelectTypeIfc

export default allSelectTypeIfcSlice.reducer