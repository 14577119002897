import React, {useCallback, useContext, useEffect, useState} from 'react'
import UserContext from "../../_shared/context/UserContext";
import {useWebSocketNotificationDispatch, useWebSocketNotificationSelector} from "../../main";
import {
    enableNotificationArrived,
    setChannels,
    setClientWS,
    setHistoryReceivedMessages
} from "../../_shared/_storeWS/_webSocketNotificationSlice";
import Queue from "../../_shared/websocket/queue/Queue";

const WebSocketManagementComponent = ({receivedMessages, setReceivedMessages}) => {

    const {user: userContext} = useContext(UserContext)
    const {clientWS, historyReceivedMessages, notificationArrived, chatRooms, channels} = useWebSocketNotificationSelector(state => state.webSocketNotification)
    const dispatchWebSocketNotification = useWebSocketNotificationDispatch()

    const [authorizationToken, setAuthorizationToken] = useState(null)
    const [setupChatRoomRun, setSetupChatRoomRun] = useState(false)

    useEffect(() => {
        if (Object.keys(receivedMessages).length > 0) {
            dispatchWebSocketNotification(setHistoryReceivedMessages(receivedMessages))
            if (!notificationArrived)
                dispatchWebSocketNotification(enableNotificationArrived())
        }
    }, [receivedMessages])

    const handleMessages = useCallback((message) => {
        const jsonObj = JSON.parse(message.body)

        // console.log("QUI", JSON.parse(message.body));
        let channel = 'all';
        if (jsonObj.channel)
        {
            channel = jsonObj.channel;
        }

        if (!receivedMessages.hasOwnProperty(channel))
        {
            receivedMessages[channel] = new Queue(100)
        }
        receivedMessages[channel].enqueue({userID: jsonObj.userID, date: jsonObj.timestamp, text: jsonObj.text, view: false})
        setReceivedMessages(prevState => ({
            ...prevState,
            [channel]: prevState[channel] = receivedMessages[channel]
        }))
    }, [receivedMessages])

    const handleWSOnConnect = (frame) => {
        //console.log("SOCKET CONNECT", frame);
        setSetupChatRoomRun(true)
    }

    const handleWSOnDisconnect = (frame) => {
        //console.log("SOCKET DISCONNECT", frame);
    }

    const uninstallChatRoomAll = () => {
        const object = {...channels}
        if (object && Object.keys(object).length > 0 && object.hasOwnProperty("all"))
        {
            if (clientWS.connected)
                object.all.unsubscribe()
            dispatchWebSocketNotification(setChannels({}))
            //console.log("UNSUBSCRIBE CHANNEL: "+`/all/messages`)
        }
    }

    const setupChatRooms = () => {
        let objects = {...channels}
        if (!objects.hasOwnProperty('all')) {
            objects = {
                ...objects,
                all: clientWS.subscribe('/all/messages', handleMessages)
            }
            //console.log("SUBSCRIBE CHANNEL: "+`/all/messages`)
        }
        if (chatRooms.length > 0)
        {
            chatRooms.forEach(chatRoom => {
                //console.log("SUBSCRIBE CHANNEL: "+`/project/${chatRoom.id}`)
                if (!objects.hasOwnProperty(chatRoom.id))
                {
                    objects = {
                        ...objects,
                        [chatRoom.id]: clientWS.subscribe(`/project/${chatRoom.id}`, handleMessages)
                    }
                }
            })
        }
        else {
            let array = {...channels}
            Object.keys(array).map(key => {
                //console.log("UNSUBSCRIBE CHANNEL: "+`/project/${key}`)
                array[key].unsubscribe();
            })
        }
        dispatchWebSocketNotification(setChannels(objects))
    }

    useEffect(() => {
        clientWS.configure({
            connectHeaders: {
                ...authorizationToken
            },
            onConnect: handleWSOnConnect,
            onDisconnect: handleWSOnDisconnect
        })
        dispatchWebSocketNotification(setClientWS(clientWS))
        return () => {
            if (clientWS.connected)
                clientWS.deactivate({force: true}).then(() => {
                    uninstallChatRoomAll()
                    //console.log("SOCKET SHUTDOWN")
                })
        }
    }, [])

    useEffect(() => {
        if (userContext.token) {
            const authToken = {Authorization: "Bearer " + userContext.token}
            // console.log("TOKEN")
            setAuthorizationToken(authToken)
        }
        else {
            setAuthorizationToken(null)
        }
    }, [userContext.token])

    useEffect(() => {
        if (!clientWS.connected && authorizationToken)
        {
            clientWS.configure({
                connectHeaders: {
                    ...authorizationToken
                }
            })
            clientWS.activate();//ABILITA IL FUNZIONAMENTO DELLE WEBSOCKET: PRODUZIONE ABILITATO, DEV COMMENTATO
        }
        else
        {
            clientWS.deactivate({force: true}).then(() => {
                uninstallChatRoomAll()
                //console.log("SOCKET SHUTDOWN")
            })
        }
    }, [authorizationToken])

    useEffect(() => {
        // console.log("USE EFFECT", chatRooms)
        if (clientWS.connected) {
            setupChatRooms()
        }
        else {
            // console.log("BROKER NOT CONNECTED YET")
        }
    }, [chatRooms])

    useEffect(() => {
        if (setupChatRoomRun) {
            // console.log("USE EFFECT", chatRooms)
            if (clientWS.connected) {
                setupChatRooms()
            } else {
                // console.log("BROKER NOT CONNECTED YET");
            }
            setSetupChatRoomRun(false)
        }
    }, [setupChatRoomRun])

    return (
        <></>
    )
}

export default WebSocketManagementComponent;