import {createSlice} from '@reduxjs/toolkit'
import {Client} from "@stomp/stompjs";
import {protocolUrlPlatformSocket, urlPlatformWithoutProtocol} from "../configuration/_global";
import {websocketDestinations} from "../websocket/_websocketConfig";

export const initialState = {
    clientWS: new Client({
        brokerURL: `${protocolUrlPlatformSocket}://${urlPlatformWithoutProtocol}${websocketDestinations.stompEndPoint}`,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
    }),
    notificationArrived: false,
    historyReceivedMessages: {},
    historySentMessages: {},
    chatRooms: [], //SONO LE CHAT CHE VENGONO PASSATE DALL'ESTERNO PER POI SUCCESSIVAMENTE COLLEGARSI
    channels: {}, //SONO I COLLEGAMENTI EFFETTUATI ALLE CHATROOM (SONO LE CHATROOM A COLLEGAMENTO EFFETTUATO)
    currentChatRoom: null
}

export const webSocketNotificationSlice = createSlice({
    name: 'webSocketNotification',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
        setClientWS: (state, action) => {
            return (action && action.payload)?{...state, clientWS: action.payload}:state
        },
        enableNotificationArrived: (state) => {
            return {...state, notificationArrived: true}
        },
        disableNotificationArrived: (state) => {
            return {...state, notificationArrived: false}
        },
        setHistoryReceivedMessages: (state, action) => {
            return (action && action.payload)?{...state, historyReceivedMessages: {...state.historyReceivedMessages, ...action.payload}}:state
        },
        setHistorySentMessages: (state, action) => {
            return (action && action.payload)?{...state, historySentMessages: {...state.historySentMessages, ...action.payload}}:state
        },
        setChatRooms: (state, action) => {
            return (action && action.payload)?{...state, chatRooms: [...action.payload]}:state
        },
        setChannels: (state, action) => {
            return (action && action.payload)?{...state, channels: {...action.payload}}:state
        },
        setCurrentChatRoom: (state, action) => {
            return (action && action.payload)?{...state, currentChatRoom: action.payload}:state
        },
        clearCurrentChatRoom: (state) => {
            return {...state, currentChatRoom: null}
        }
    },
})

// Action creators are generated for each case reducer function
export const { clear, setClientWS, enableNotificationArrived, disableNotificationArrived, setHistoryReceivedMessages,
    setHistorySentMessages, setChatRooms, setChannels, setCurrentChatRoom, clearCurrentChatRoom} = webSocketNotificationSlice.actions

export const selectWebSocketNotification = (state) => state.webSocketNotification

export default webSocketNotificationSlice.reducer