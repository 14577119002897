import {createSlice} from '@reduxjs/toolkit'

export const initialState = false

export const materialXRaySlice = createSlice({
    name: 'materialXRay',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        switchValue: (state) => {
            return !state
        },
        set: (state, action) => {
            return (action && typeof action.payload === 'boolean')?action.payload:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, switchValue, set } = materialXRaySlice.actions

export const selectMaterialXRay = (state) => state.materialXRay

export default materialXRaySlice.reducer