const websocketDestinations = {
    stompEndPoint: "/ws",
    destinationPrefix: "/socket",
}

const websocketMessageMapping = {
    all: websocketDestinations.destinationPrefix+"/application",
    private: websocketDestinations.destinationPrefix+"/private",
    project: websocketDestinations.destinationPrefix+"/project/"
}

export {websocketDestinations, websocketMessageMapping}