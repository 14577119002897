import {legacy_createStore as createStore} from '@reduxjs/toolkit'

const arrayStorage = ['user', 'token', 'refreshToken', 'roles', 'currentRole']

const initialState = {
  sidebarShow: false,
  user: localStorage.getItem('user') === undefined?null:(JSON.parse(localStorage.getItem('user')) || null),
  roles: localStorage.getItem('roles') === undefined?null:(JSON.parse(localStorage.getItem('roles')) || null),
  currentRole: localStorage.getItem('currentRole') === undefined?null:(JSON.parse(localStorage.getItem('currentRole')) || null),
  token: localStorage.getItem('token') === undefined?null:(JSON.parse(localStorage.getItem('token')) || null),
  refreshToken: localStorage.getItem('refreshToken') === undefined?null:(JSON.parse(localStorage.getItem('refreshToken')) || null),
}

const changeState = (state = initialState, { type, name, ...rest }) => {
  switch (type) {
    case 'set':
      if (arrayStorage.includes(name))
      {
        localStorage.setItem(name, JSON.stringify(rest[name]));
      }
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)

export default store