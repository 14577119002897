import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    position: {x: 40, y: 40},
}

export const lightCoordinateSlice = createSlice({
    name: 'lightCoordinate',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = lightCoordinateSlice.actions

export const selectLightCoordinate = (state) => state.lightCoordinate

export default lightCoordinateSlice.reducer