import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {Suspense, useMemo, useRef, useState} from "react";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {AppLoading, LoadingContext} from "./components/loading/AppLoading";
import {ToastContext} from "./components/toast/AppToast";
import {CToaster} from "@coreui/react";
//I18N
import './_shared/configuration/i18n';
import {useSelector} from "react-redux";
import UserContext from "./_shared/context/UserContext";
import AuthExpirationVerifyComponent from "./_shared/_blockpage/AuthExpirationVerifyComponent";
import WebSocketManagementComponent from "./components/websocket/WebSocketManagementComponent";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const DefaultAdminLayout = React.lazy(() => import("./layout/DefaultAdminLayout")
);

// Pages
const Login = React.lazy(() => import("./views/login/Login"));
const Newsletter = React.lazy(() => import("./views/newsletter/Newsletter"));
const ChangePassword = React.lazy(() => import("./views/login/ChangePassword"));
const Register = React.lazy(() => import("./views/register/Register"));
const Error = React.lazy(() => import("./views/error/Error"));
const Page404 = React.lazy(() => import("./views/404/Page404"));
const Page500 = React.lazy(() => import("./views/500/Page500"));

export default function App() {

    const loadingFallback = useMemo(() =>
            <AppLoading />
        , []);

    //GESTIONE SESSIONE UTENTE: START
    const user = useSelector((state) => state.user || null);
    const rolesTemp = useSelector((state) => state.roles || null);
    const currentRoleTemp = useSelector((state) => state.currentRole || null);
    const token = useSelector((state) => state.token || null);

    const [userContext, setUserContext] = useState({
        user: user,
        roles: rolesTemp,
        currentRole: currentRoleTemp,
        token: token
    })
    const valueUserContext = useMemo(
        () => ({ user: {...userContext}, setUser: setUserContext }),
        [userContext]
    )
    //GESTIONE SESSIONE UTENTE: END

    const [loading, setLoading] = useState(false);
    const valueLoading = useMemo(
        () => ({ loading, setLoading }),
        [loading]
    );

    const [toast, addToast] = useState(0)
    const toaster = useRef()
    const valueToast = useMemo(
        () => ({ toast, addToast }),
        [toast]
    );

    const [receivedMessages, setReceivedMessages] = useState({})

    return (
        <LoadingContext.Provider value={valueLoading}>
            {loading && <AppLoading className={'opacity-50'} />}
            <UserContext.Provider value={valueUserContext}>
                { token && <WebSocketManagementComponent receivedMessages={receivedMessages} setReceivedMessages={setReceivedMessages} /> }
                <ToastContext.Provider value={valueToast}>
                    <CToaster ref={toaster} push={toast} placement={'top-end'} />
                    {/* CAMBIARE QUI basename PER PRODUCTION A SECONDA DELLE ESIGENZE (es. TOMCAT) basename={"/palantirbim_fe"}*/}
                    <BrowserRouter>
                        <Suspense fallback={loadingFallback}>
                            <Routes>
                                <Route exact path="/login" id="Login Page" element={<Login />} />
                                <Route exact path="/reset-password" id="Reset Password" element={<ChangePassword />} />
                                {/*<Route exact path="/register" id="Register Page" element={<Register />} />*/}
                                <Route path="/newsletter/:id?/:code?" id="Newsletter" element={<Newsletter />} />
                                <Route exact path="/error" id="Page Error" element={<Error />} />
                                <Route exact path="/404" id="Page 404" element={<Page404 />} />
                                <Route exact path="/500" id="Page 500" element={<Page500 />} />
                                <Route path="/admin/*" id="Admin" element={<DefaultAdminLayout />} />
                                <Route path="*" id="Generic" element={<DefaultLayout />} />
                            </Routes>
                            <AuthExpirationVerifyComponent />
                        </Suspense>
                    </BrowserRouter>
                </ToastContext.Provider>
            </UserContext.Provider>
        </LoadingContext.Provider>
    );
}
library.add(fas)