import React, {createContext} from 'react'
import {Spinner} from "react-bootstrap";

const AppLoading = ({className = ''}) => {

    return (
        <div className={`palantirbim-loader ${className}`}>
            <div className="palantirbim-loading">
                <div className={'text-center'}>
                    <h2 className={'text-light'}>PalantirBIM</h2>
                    <Spinner variant="primary" animation="border" role="status" size="lg">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </div>
        </div>
    )
}

const LoadingContext = createContext({
    loading: false,
    setLoading: () => {}
});

export { AppLoading, LoadingContext }