import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    switchCamera: false,
    gridVisible: true
}

export const switchCameraSlice = createSlice({
    name: 'switchCamera',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        switchValue: (state) => {
            return {...state, switchCamera: !state.switchCamera}
        },
        set: (state, action) => {
            return (action && typeof action.payload === 'boolean')?{...state, switchCamera: action.payload}:state
        },
        switchGridVisible: (state) => {
            return {...state, gridVisible: !state.gridVisible}
        }
    },
})

// Action creators are generated for each case reducer function
export const { clear, switchValue, set, switchGridVisible } = switchCameraSlice.actions

export const selectSwitchCamera = (state) => state.switchCamera

export default switchCameraSlice.reducer