import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    modelIDs: [],
    expressID: "",
    type: "",
}

export const itemAutocompleteSlice = createSlice({
    name: 'itemAutocomplete',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = itemAutocompleteSlice.actions

export const selectItemAutocomplete = (state) => state.itemAutocomplete

export default itemAutocompleteSlice.reducer