import React from 'react'

//ORDINE DISCENDENTE y - x //ORDINE ASCENDENTE x - y
const compare = (x, y) => {
    return new Date(y.date).getTime() - new Date(x.date).getTime()
}

class Queue {
    constructor(size) {
        this.elements = [];
        this.size = size;
    }

    enqueue(node) {
        if (this.elements.length + 1 >= this.size)
            this.dequeue();
        this.elements.push({...node});
    }

    dequeue() {
        if(this.elements.length > 0) {
            return this.elements.shift();
        } else {
            console.log("Underflow situation");
            throw new Error("Underflow situation");
        }
    }

    isEmpty() {
        return this.elements.length === 0;
    }

    front() {
        if(this.elements.length > 0) {
            return this.elements[0];
        } else {
            console.log("The Queue is empty!")
            throw new Error("The Queue is empty!");
        }
    }

    length() {
        return this.elements.length;
    }

    getElements() {
        return this.elements;
    }

    getNotReaded() {
        return this.elements.map((item, index) => { return {...item, index: index} }).filter(item => item.view === false).sort(compare);
    }

    getReaded() {
        return this.elements.map((item, index) => { return {...item, index: index} }).filter(item => item.view === true).sort(compare);
    }

    setReadedByIndex(index) {
        if (index < this.elements.length)
        {
            this.elements[index].view = true
        }
    }

    setAllReaded() {
        this.elements.forEach(item => item.view = false)
    }

    lengthNotReaded() {
        return this.getNotReaded().length;
    }

    lengthNotReadedExcludeUserID(userID) {
        return this.getNotReaded().filter(item => item.userID !== userID).length;
    }

    lengthReaded() {
        return this.getReaded().length;
    }

    clearNotReaded() {
        this.elements.forEach(item => {
            if (!item.view)
                item.view = true;
        })
    }
}

export default Queue;