import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    spatialStructure: {},
    checkedSpatialStructure: {}
}

export const spatialStructureSlice = createSlice({
    name: 'spatialStructure',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
        setCheckedSpatialStructure: (state, action) => {
            return (action && action.payload)?{...state, checkedSpatialStructure: {...action.payload}}:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set, setCheckedSpatialStructure } = spatialStructureSlice.actions

export const selectSpatialStructure = (state) => state.spatialStructure

export default spatialStructureSlice.reducer