import {configureStore} from '@reduxjs/toolkit'
import webSocketNotificationReducer from './_storeWS/_webSocketNotificationSlice'

const storeWebSocketNotification = configureStore({
  reducer: {
    webSocketNotification: webSocketNotificationReducer,
  },
  //MODIFICA PER FIX: "A non-serializable value was detected in the state" quando
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export default storeWebSocketNotification