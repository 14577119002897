import {createSlice} from '@reduxjs/toolkit'

export const initialState = {}

export const itemsSearchSlice = createSlice({
    name: 'itemsSearch',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = itemsSearchSlice.actions

export const selectItemsSearch = (state) => state.itemsSearch

export default itemsSearchSlice.reducer