import {createSlice} from '@reduxjs/toolkit'

export const initialState = []

export const itemsSelectedSlice = createSlice({
    name: 'itemsSelected',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?[...action.payload]:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = itemsSelectedSlice.actions

export const selectItemsSelected = (state) => state.itemsSelected

export default itemsSelectedSlice.reducer