import React from "react";
import ReactDOM from "react-dom/client";
import {createDispatchHook, createSelectorHook, Provider} from "react-redux";
import store from "./_shared/_store";
import storeViewer from "./_shared/_storeViewer";
import "./Theme.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./_custom.scss";
import "./App.css";
import App from "./App";
import storeWebSocketNotification from "./_shared/_storeWebSocketNotification";

const root = ReactDOM.createRoot(document.getElementById("root"))

const storeViewerContext = React.createContext(null);
export const useViewerSelector = createSelectorHook(storeViewerContext)
export const useViewerDispatch = createDispatchHook(storeViewerContext)

const storeWebSocketNotificationContext = React.createContext(null);
export const useWebSocketNotificationSelector = createSelectorHook(storeWebSocketNotificationContext)
export const useWebSocketNotificationDispatch = createDispatchHook(storeWebSocketNotificationContext)

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <Provider context={storeViewerContext} store={storeViewer}>
            <Provider context={storeWebSocketNotificationContext} store={storeWebSocketNotification}>
                <App />
            </Provider>
        </Provider>
    </Provider>
    // </React.StrictMode>
);