import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    modelID: {type: "STRING", value: "", editable: false},
    ID: {type: "STRING", value: "", editable: false},
    IFCTYPE: {type: "STRING", value: "", editable: false}
}

export const itemContextMenuSlice = createSlice({
    name: 'itemContextMenu',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = itemContextMenuSlice.actions

export const selectItemContextMenu = (state) => state.itemContextMenu

export default itemContextMenuSlice.reducer