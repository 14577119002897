import {configureStore} from '@reduxjs/toolkit'
import switchCameraReducer from './_storeViewer/_switchCameraSlice'
import materialXRayReducer from './_storeViewer/_materialXRaySlice'
import changeVisibilityItemReducer from './_storeViewer/_changeVisibilityItemSlice'
import allSelectTypeIfcReducer from './_storeViewer/_allSelectTypeIfcSlice'
import itemContextMenuReducer from './_storeViewer/_itemContextMenuSlice'
import itemSearchReducer from './_storeViewer/_itemSearchSlice'
import itemsSearchReducer from './_storeViewer/_itemsSearchSlice'
import itemAutocompleteReducer from './_storeViewer/_itemAutocompleteSlice'
import spatialStructureReducer from './_storeViewer/_spatialStructureSlice'
import clippingPlanesReducer from './_storeViewer/_clippingPlanesSlice'
import itemSelectedReducer from './_storeViewer/_itemSelectedSlice'
import itemsSelectedReducer from './_storeViewer/_itemsSelectedSlice'
import lightCoordinateReducer from './_storeViewer/_lightCoordinateSlice'

const storeViewer = configureStore({
  reducer: {
    switchCamera: switchCameraReducer,
    materialXRay: materialXRayReducer,
    changeVisibilityItem: changeVisibilityItemReducer,
    allSelectTypeIfc: allSelectTypeIfcReducer,
    itemContextMenu: itemContextMenuReducer,
    itemSearch: itemSearchReducer,
    itemsSearch: itemsSearchReducer,
    itemAutocomplete: itemAutocompleteReducer,
    spatialStructure: spatialStructureReducer,
    clippingPlanes: clippingPlanesReducer,
    itemSelected: itemSelectedReducer,
    itemsSelected: itemsSelectedReducer,
    lightCoordinate: lightCoordinateReducer
  },
  //MODIFICA PER FIX: "A non-serializable value was detected in the state" quando
  // dispatchViewer(setClippingPlanesStore({
  //    currentPlane: (clippingPlanes.currentPlane >= 0) ? clippingPlanes.currentPlane : -1,
  //    planes: [...array]
  // }))
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export default storeViewer