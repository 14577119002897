import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    currentPlane: -1,
    planes: [],
    visible: false,
}

export const clippingPlanesSlice = createSlice({
    name: 'clippingPlanes',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        reset: (state) => {
          return {
              ...state,
              currentPlane: -1
          }
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
        setCurrentPlane: (state, action) => {
            return (action && action.payload && !isNaN(action.payload))?{...state, currentPlane: action.payload}:state
        },
        setPlanes: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?{...state, planes: [...action.payload]}:state
        },
        setVisible: (state, action) => {
            return (action && typeof action.payload == 'boolean')?{...state, visible: action.payload}:state
        }
    },
})

// Action creators are generated for each case reducer function
export const { clear, reset, set, setCurrentPlane, setPlanes, setVisible } = clippingPlanesSlice.actions

export const selectClippingPlanes = (state) => state.clippingPlanes

export default clippingPlanesSlice.reducer