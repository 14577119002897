const jsonConfiguration = {
    version: "0.0.2",
    active: "prod",
    server: {
        local: {
            protocolUrlPlatform: "http",
            protocolUrlPlatformSocket: "ws",
            addressUrlPlatform: "localhost",
            portUrlPlatform: ":8080",
            prefixUrlPlatform: "/palantirbim"
        },
        dev: {
            protocolUrlPlatform: "http",
            protocolUrlPlatformSocket: "ws",
            addressUrlPlatform: "localhost",
            portUrlPlatform: ":8080",
            prefixUrlPlatform: "/palantirbim-0.0.1"
        },
        prod: {
            protocolUrlPlatform: "https",
            protocolUrlPlatformSocket: "wss",
            addressUrlPlatform: "palantir-bim.it",
            portUrlPlatform: ":80",
            prefixUrlPlatform: "/palantirbim"
        }
    }
}

const version = jsonConfiguration['version']

const protocolUrlPlatform = jsonConfiguration['server'][jsonConfiguration['active']]['protocolUrlPlatform']
// const protocolUrlPlatform = "https" //PROD
const addressUrlPlatform = jsonConfiguration['server'][jsonConfiguration['active']]['addressUrlPlatform']
// const addressUrlPlatform = "palantir-bim.it" //PROD
const portUrlPlatform = jsonConfiguration['server'][jsonConfiguration['active']]['portUrlPlatform']
// const portUrlPlatform = ":80" //PROD
const prefixUrlPlatform = jsonConfiguration['server'][jsonConfiguration['active']]['prefixUrlPlatform'] //DEV
// const prefixUrlPlatform = "/palantirbim" //PROD
const protocolUrlPlatformSocket = jsonConfiguration['server'][jsonConfiguration['active']]['protocolUrlPlatformSocket']

const urlPlatformWithoutProtocol = addressUrlPlatform + ((portUrlPlatform !== ":80")?portUrlPlatform:"") + prefixUrlPlatform;
const urlPlatform = protocolUrlPlatform + "://" + addressUrlPlatform + ((portUrlPlatform !== ":80")?portUrlPlatform:"") + prefixUrlPlatform;

const UserTypology = {
    ALL: [],
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_BIM_MANAGER: 'ROLE_BIM_MANAGER',
    ROLE_BIM_COORDINATOR: 'ROLE_BIM_COORDINATOR',
    ROLE_BIM_SPECIALIST: 'ROLE_BIM_SPECIALIST',
    ROLE_TUTELA_COMMITTENTE: 'ROLE_TUTELA_COMMITTENTE',
    ROLE_USER: 'ROLE_USER',
}

const projectStatus = {
    INITIATING: "INITIATING", DEVELOPING: "DEVELOPING", CLOSING: "CLOSING"
}

const taskStatus = {
    INITIATING: "INITIATING BETA MODEL",
    L0_MODEL: "L0 MODEL",
    L0_MODEL_TO_BE_APPROVED: "L0 MODEL TO BE APPROVED",
    L1_MODEL: "L1 MODEL",
    L1_MODEL_TO_BE_APPROVED: "L1 MODEL TO BE APPROVED",
    L2_MODEL: "L2 MODEL",
    L2_MODEL_TO_BE_APPROVED: "L2 MODEL TO BE APPROVED",
    L3_MODEL: "L3 MODEL",
}

const taskActions = {
    CREATE: "CREATE", UPDATE: "UPDATE", UPLOADFILE: "UPLOADFILE", DELETE: "DELETE"
}

const sensorTypeMapper = {
    LABEL: "LABEL",
    VALUE: "VALUE",
    DATE: "DATE",
    TIME: "TIME",
    DATETIME: "DATETIME"
}

const sensorLimitType = {
    min: "min",
    max: "max",
}

const blockPageData = {
    model: {
        project: 'project',
        task: 'task',
        sensor: 'sensor',
        sensor_mapping: 'sensor_mapping',
        favorite_properties: 'favorite_properties',
    },
    operation: {
        edit: 'edit',
        history: 'history',
        upload: 'upload',
        type: 'type'
    }
}

export { UserTypology, urlPlatform, urlPlatformWithoutProtocol, projectStatus, taskStatus, taskActions, sensorTypeMapper, sensorLimitType, blockPageData, protocolUrlPlatformSocket, version };