import React, {useContext, useEffect} from 'react'
import {withRouter} from "./WithRouteComponent";
import UserContext from "../context/UserContext";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useWebSocketNotificationDispatch} from "../../main";
import {
    disableNotificationArrived,
    setChatRooms,
    setHistoryReceivedMessages,
    setHistorySentMessages
} from "../_storeWS/_webSocketNotificationSlice";

const AuthExpirationVerifyComponent = (props) => {

    const {user: userContext, setUser} = useContext(UserContext)
    //SOCKET: START
    const dispatchWebSocketNotification = useWebSocketNotificationDispatch()
    //SOCKET: END
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let location = props.router.location

    const handleLogout = () => {
        dispatch({ type: "set", name: 'token', token: null });
        dispatch({ type: "set", name: 'refreshToken', refreshToken: null });
        dispatch({ type: "set", name: 'user', user: null });
        dispatch({ type: "set", name: 'roles', roles: null });
        dispatch({ type: "set", name: 'currentRole', currentRole: null });

        const userContextTmp = {...userContext}
        userContextTmp.user = null
        userContextTmp.roles = null
        userContextTmp.currentRole = null
        userContextTmp.token = null
        setUser(userContextTmp)

        dispatchWebSocketNotification(setChatRooms([]))
        dispatchWebSocketNotification(setHistoryReceivedMessages({}))
        dispatchWebSocketNotification(setHistorySentMessages({}))
        dispatchWebSocketNotification(disableNotificationArrived())
        navigate(`/login`, {replace: true});
    }

    useEffect(() => {
        if (userContext && userContext.user && userContext.user.expiration) {
            try {
                const timestamp = new Date(userContext.user.expiration).getTime()
                // const timestamp = new Date( Date.now() - 1000 * 60 ) //MOCK 1 MINUTO INDIETRO
                if (timestamp < Date.now()) {
                    handleLogout()
                }
            }
            catch (error) {
                console.error(error)
            }
        }
    }, [location])

    return <></>;
}

export default withRouter(AuthExpirationVerifyComponent);