import React, {createContext} from 'react'
import {CToast, CToastBody, CToastHeader} from "@coreui/react";

const AppToast = (currentMessage) => {

    const time = new Date().toLocaleString();

    return(
        <CToast>
            <CToastHeader closeButton>
                <svg
                    className="rounded me-2"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                    role="img"
                >
                    {currentMessage.type && (
                        <rect width="100%" height="100%" fill={currentMessage.type}/>
                    )}
                    {!currentMessage.type && (<rect width="100%" height="100%" fill="#FFF"/>)}
                </svg>
                {currentMessage.title && (<div className="fw-bold me-auto">{currentMessage.title}</div>)}
                <small>{time}</small>
            </CToastHeader>
            {currentMessage.body && (<CToastBody>{currentMessage.body}</CToastBody>)}
        </CToast>
    )
}

const ToastContext = createContext({
    toast: [],
    addToast: () => {}
});

export {AppToast, ToastContext};