import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    modelID: -1,
    id: -1,
    ifcType: "",
    itemProperties: null,
    typeProperties: [],
    propertySets: [],
    materialsProperties: []
}

export const itemSelectedSlice = createSlice({
    name: 'itemSelected',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        reset: (state) => {
            return null
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
        setModelID: (state, action) => {
            return (action && action.payload && !isNaN(action.payload))?{...state, modelID: action.payload}:state
        },
        setID: (state, action) => {
            return (action && action.payload && !isNaN(action.payload))?{...state, id: action.payload}:state
        },
        setIfcType: (state, action) => {
            return (action && action.payload && typeof action.payload == 'string')?{...state, ifcType: action.payload}:state
        },
        setItemProperties: (state, action) => {
            return (action && action.payload && Object.keys(action.payload).length > 0)?{...state, itemProperties: action.payload}:state
        },
        setTypeProperties: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?{...state, typeProperties: [...action.payload]}:state
        },
        setPropertySets: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?{...state, propertySets: [...action.payload]}:state
        },
        setMaterialsProperties: (state, action) => {
            return (action && action.payload && Array.isArray(action.payload))?{...state, materialsProperties: [...action.payload]}:state
        }
    },
})

// Action creators are generated for each case reducer function
export const { clear, reset, set, setModelID, setID, setIfcType, setItemProperties, setTypeProperties, setPropertySets, setMaterialsProperties } = itemSelectedSlice.actions

export const selectItemSelected = (state) => state.itemSelected

export default itemSelectedSlice.reducer