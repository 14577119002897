import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    modelIDs: [],
    expressID: "",
    type: "",
}

export const itemSearchSlice = createSlice({
    name: 'itemSearch',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
        set: (state, action) => {
            return (action && action.payload)?{...state, ...action.payload}:state
        },
    },
})

// Action creators are generated for each case reducer function
export const { clear, set } = itemSearchSlice.actions

export const selectItemSearch = (state) => state.itemSearch

export default itemSearchSlice.reducer